<template>
  <div class="table-container columnd gap24">
    <div class="btns rowd">
      <button
        @click="deposite = true"
        :class="{ 'toggle-active': deposite }"
        class="toggle"
      >
        <span class="f16-700"> Deposite </span>
        <transition name="borderrotate">
          <span v-if="deposite" class="underline"></span>
        </transition>
      </button>

      <button
        @click="deposite = false"
        :class="{ 'toggle-active': !deposite }"
        class="toggle"
      >
        <span class="f16-700"> WithDrawal </span>
        <transition name="borderrotate">
          <span v-if="!deposite" class="underline"></span>
        </transition>
      </button>
    </div>
    <div class="table columnd gap12">
      <div class="header rowd w100 space-between">
        <span style="flex: 1.5" class="justify-start">Time</span>
        <span style="flex: 1.5">Coin</span>
        <span style="flex: 1.5" class="justify-center">Amount</span>
        <span style="flex: 1.5" class="justify-center">Network</span>
        <span style="flex: 1.5" class="justify-center"
          >Destination Address</span
        >
        <span style="flex: 1" class="justify-center">Status</span>
        <span style="flex: 1" class="justify-end">TX ID</span>
      </div>
      <div v-for="i in 10" :key="i" class="body rowd w100 space-between gap8">
        <span style="flex: 1.5" class="justify-start f14-400 c-darkGrey"
          >11:32 - 22/01/1</span
        >
        <span style="flex: 1.5" class="f14-400 c-darkGrey">Bitcoin - BTC</span>
        <span style="flex: 1.5" class="justify-center f14-400 c-darkGrey"
          >0.45</span
        >
        <span style="flex: 1.5" class="justify-center f14-400 c-darkGrey"
          >TRC20</span
        >
        <span style="flex: 1.5" class="justify-center f14-400 c-darkGrey"
          >-</span
        >
        <span style="flex: 1" class="justify-center c-red f14-400">Fail</span>
        <span style="flex: 1" class="justify-end link12-400 c-darkGrey"
          >Open Link</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Transactions",
  data() {
    return {
      deposite: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: white !important;
}
.toggle-active {
  background: var(--llGrey) !important;
}
</style>
