<template>
  <div class="table-container columnd gap24 w100">
    <div class="btns rowd">
      <button
        @click="buy = true"
        :class="{ 'toggle-active': buy }"
        class="toggle"
      >
        <span class="f16-700"> Buy </span>
        <transition name="borderrotate">
          <span v-if="buy" class="underline"></span>
        </transition>
      </button>

      <button
        @click="buy = false"
        :class="{ 'toggle-active': !buy }"
        class="toggle"
      >
        <span class="f16-700"> Sell </span>
        <transition name="borderrotate">
          <span v-if="!buy" class="underline"></span>
        </transition>
      </button>
    </div>
    <div class="table columnd gap12">
      <div
        :class="{
          'dash-header':
            $route.name === 'Dashboard' || $route.name === 'History',
        }"
        class="header rowd w100 space-between"
      >
        <span class="remove" style="flex: 2; text-align: start">Time</span>
        <span style="flex: 2" v-if="$route.name !== 'User'">{{
          buy ? "Buyer" : "Seller"
        }}</span>
        <span style="flex: 1.5">{{ buy ? "Pay via" : "Get Paied via" }}</span>
        <span style="flex: 2">Amount</span>
        <span style="flex: 1.5">Fee</span>
        <span style="flex: 1.5">Total</span>
        <span style="flex: 1.5"></span>
      </div>
      <div v-for="i in 10" :key="i" class="body rowd w100 space-between">
        <span
          class="f14-400 remove"
          style="flex: 2; text-align: start; justify-content: start"
        >
          2021/03/04 _ 21:30
        </span>
        <div
          @click="$router.push({ name: 'User' })"
          v-if="$route.name !== 'User'"
          class="rowd gap4 align-center justify-center pointer"
          style="flex: 2; z-index: 2"
        >
          <img class="br8" src="@/assets/test/fakeprofile.svg" alt="" />
          <div class="columnd gap8">
            <h4 class="f14-700 c-darkBlue">Ryan Gasling</h4>
            <div class="isonline">
              <span class="circle on"></span>
              <span class="f14-700 c-black"> online </span>
            </div>
          </div>
        </div>
        <span class="f14-400 c-medGrey" style="flex: 1.5">Pay Pal</span>
        <span class="f14-400 c-medGrey" style="flex: 2">0.001 ETH</span>
        <span class="f14-400 c-medGrey" style="flex: 1.5">2.4 USD </span>
        <span class="f16-700 c-darkGrey" style="flex: 1.5">1.3 USD</span>
        <span class="f12-400 c-green pointer" style="flex: 1.5"> Success </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveOrdersHistory",
  data() {
    return {
      buy: true,
    };
  },
  methods: {
    reDShop(val) {
      val
        ? this.$router.push({ name: "BuyFrom" })
        : this.$router.push({ name: "SellFrom" });
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-btn {
  max-width: 100px !important;
}
.toggle-active {
  background: var(--llGrey) !important;
}
</style>
