<template>
  <div class="table columnd gap12 w100">
    <div class="header rowd w100 space-between">
      <span class="remove" style="flex: 2; text-align: start">Time</span>
      <span style="flex: 2">{{ buy ? "Buyer" : "Seller" }}</span>
      <span style="flex: 1.5">Time Left</span>
      <span style="flex: 1.5">{{ buy ? "Pay via" : "Get Paied via" }}</span>
      <span style="flex: 2">Amount</span>
      <span style="flex: 1.5">Fee</span>
      <span style="flex: 1.5">Total</span>
    </div>
    <div v-for="i in 3" :key="i" class="body rowd w100 space-between">
      <span
        class="f14-400 remove"
        style="flex: 2; text-align: start; justify-content: start"
      >
        2021/03/04 _ 21:30
      </span>
      <div class="rowd gap4 align-center justify-center" style="flex: 2">
        <div class="columnd gap8">
          <h4 class="f14-700 c-darkOrange">Ryan Gasling</h4>
        </div>
      </div>
      <span
        class="f14-400 c-medGrey dflex align-center gap4 justify-center"
        style="flex: 1.5"
      >
        <inline-svg :src="require('@/assets/Icons/clock.svg')" />
        12:01
      </span>
      <span class="f14-400 c-medGrey" style="flex: 1.5">Pay Pal</span>
      <span class="f14-400 c-medGrey" style="flex: 2">0.001 ETH</span>
      <span class="f14-400 c-medGrey" style="flex: 1.5">2.4 USD </span>
      <span class="f16-700 c-darkGrey" style="flex: 1.5">1.3 USD</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveOrdersHistory",
  props: ["buy"],
};
</script>

<style lang="scss" scoped>
.header {
  background: var(--lightGrey);
}
.header,
.body {
  border: none !important;
}
.toggle-active {
  background: var(--llGrey) !important;
}
</style>
