<template>
  <div class="columnd gap32 w100 mini-container">
    <div class="btns rowd" :class="{ gap8: $route.name === 'Dashboard' }">
      <button
        @click="selectedTable = 'Orders'"
        :class="[
          { 'toggle-active': selectedTable === 'Orders' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Orders',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Orders </span>
        <transition name="borderrotate">
          <span v-if="selectedTable === 'Orders'" class="underline"></span>
        </transition>
      </button>

      <button
        v-if="$route.name !== 'User'"
        @click="selectedTable = 'Posting'"
        :class="[
          { 'toggle-active': selectedTable === 'Posting' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Posting',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Posting </span>
        <transition name="borderrotate">
          <span v-if="selectedTable === 'Posting'" class="underline"></span>
        </transition>
      </button>

      <button
        @click="selectedTable = 'Transactions'"
        :class="[
          { 'toggle-active': selectedTable === 'Transactions' },
          {
            'b-llGrey c-darkBlue':
              $route.name === 'Dashboard' && selectedTable === 'Transactions',
          },
        ]"
        class="toggle"
      >
        <span class="f16-700"> Transactions </span>
        <transition name="borderrotate">
          <span
            v-if="selectedTable === 'Transactions'"
            class="underline"
          ></span>
        </transition>
      </button>
    </div>
    <ActiveOrders v-if="selectedTable === 'Orders'" />
    <ActivePosting v-if="selectedTable === 'Posting'" />
    <Transactions v-if="selectedTable === 'Transactions'" />
  </div>
</template>

<script>
import ActiveOrders from "./Tables/Orders.vue";
import ActivePosting from "./Tables/Posting.vue";
import Transactions from "./Tables/Transactions.vue";
export default {
  name: "ProfileTable",
  components: {
    ActiveOrders,
    ActivePosting,
    Transactions,
  },
  data() {
    return {
      selectedTable: "Orders",
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle-active {
  background: var(--llGrey);
  color: var(--darkBlue);
}
</style>
